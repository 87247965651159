import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Image } from 'rebass';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "read-confirmations---automation"
    }}>{`Read Confirmations - Automation`}</h1>
    <br />
    <p>{`You can automate the process for adding read confirmation requests to pages.`}<br />{`
Simply go into the space administration and select "Read Confirmations" from the menu. From here, you can click "Rules" to set up automation rules.`}</p>
    <br />
    <Image width={'80%'} src='/public/Automation.png' sx={{
      m: 1,
      ml: 0
    }} mdxType="Image" />
    <p>{`How the rules work:`}</p>
    <ul>
      <li parentName="ul">{`You can add a general rule to capture every page or blog posts within a space - be careful, this will generate a lot of load on active spaces.`}</li>
      <li parentName="ul">{`If you want to apply the rules only to certain pages, define a label and let the automation react to it.`}</li>
      <li parentName="ul">{`The label automation will overrule any less specific rule - there will only be one read confirmation request added for a page`}</li>
      <li parentName="ul">{`If a page already contains a read confirmation request and the rule is triggered, no additional action will be taken`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      